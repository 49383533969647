import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby'

const GlobalStyle = createGlobalStyle`
  .header-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    h2 {
      font: italic bold 28px 'Times new roman';
      color: #000000;
    }

    .close-modal {
      margin-left: auto;
      background: #f1a857;
      height: 34px;
      width: 53px;
      border-radius: 3px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: .2s all ease;

      i {
        background: url("/images/streamline-icon-close-140-x-140.png") no-repeat center;
        background-size: contain;
        width: 17px;
        height: 17px;
      }

      &:hover {
        background: #e59134;
      }
    }

    @media (min-width: 767px) {
      margin-bottom: 45px;

      h2 {
        font: italic bold 45px 'Times new roman';
      }

      .close-modal {
        height: 50px;
        width: 83px;

        i {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;

export default function HeaderModal({ modal, closeTo }) {
  return (
    <>
      <div className="header-modal">
        <h2>Edite seu Projeto</h2>

        {modal && (
          <Link to={closeTo} className="close-modal">
            <i></i>
          </Link>
        )}
      </div>

      <GlobalStyle />
    </>
  )
}