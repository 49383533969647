import React, { useState, useEffect, useContext } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/auth";
import { useStaticQuery, graphql } from "gatsby";

import api from "../../services/api";

import { estados } from "../../assets/cidades.json";

import HeaderModal from "./header";
import { navigate } from "gatsby";
import Header from "../../components/header";
import Newsletter from "../../components/newsletter";
import Footer from "../../components/footer";

const GlobalStyle = createGlobalStyle`
  .ReactModal__Overlay {
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.6)!important;
  }

  .modal-content.modal-out {
    width: 100%;
    padding: 50px 20px;
    margin: 0 auto;
  }
  .modal-background {
    background: #fff;
  }

  .ReactModal__Content {
    left: 20px!important;
    right: 20px!important;
    border: 0!important;
  }

  .content-modal {
    padding-top: 45px;
    padding-bottom: 30px;

    .list-preview {
      display: flex;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .dropzone {
    background: #efefef;
    border: 2px dashed #f1a857;
    height: 555px;
    width: 100%;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    position: relative;

    h3 {
      font: italic bold 18px 'Times new roman';
      color: #000000;
      margin-bottom: 12px;
      max-width: 213px;
    }

    i {
      width: 80px;
      height: 80px;
      display: block;
      background: url('/images/streamline-icon-image-file-upload-140-x-140-black.png') no-repeat center;
    }

    p {
      position: absolute;
      bottom: 40px;
      font-size: 14px;
      color: #787878;
      max-width: 200px;
      font-weight: 500;
    }
  }

  .about-fields {
    padding-top: 20px;
    padding-bottom: 30px;

    .half {
      display: flex;
      justify-content: space-between;

      select {
        width: 48%;
        min-width: auto;
      }
    }

    input,
    select {
      margin-bottom: 20px;
    }

    .input-error {
      border-bottom: 1px solid #bd0303;
      color: #bd0303;

      &::placeholder {
        color: #bd0303;
      }
    }
    .input-error-area {
      border: 1px solid #bd0303;
      color: #bd0303;

      &::placeholder {
        color: #bd0303;
      }
    }

    textarea {
      margin-top: 10px;
    }
  }

  .button-submit {
    cursor: pointer;
    max-width: 300px;
    width: 100%;
    height: 60px;
    border-radius: 3px;
    border: 0;
    margin: 0 auto;
    background: #f1a857;

    display: flex;
    align-items: center;

    transition: .2s all ease;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      padding-left: 35px;
      padding-right: 30px;

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i {
      width: 73px;
      height: 100%;
      border-left: 1px solid #d99440;
      margin-left: auto;
      background: url('/images/streamline-icon-send-email-140-x-140.png') center no-repeat;
      background-size: 24px;
    }

    &:hover {
      background: #E59134;

      span {
        color: #ffffff;
      }

      i {
        border-left: 1px solid #CD8226;
      }
    }
  }

  .used-products {
    margin-bottom: 40px;

    h3 {
      font: italic bold 22px 'Times new roman';
      color: #000000;
      margin-bottom: 25px;
    }

    h4 {
      font: italic bold 22px 'Times new roman';
      color: #000000;
      margin-bottom: 20px;
    }
  }

  .dd {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #648540 url('/images/arrow-right.png') no-repeat center;

      top: calc(50% - 60px);
    }

    &::before {
      left: calc(50% - 60px);
      transform: rotate(90deg);
    }

    &::after {
      left: calc(50%);
      transform: rotate(-90deg);
    }

    & > div {
      width: 100%!important;
      border: 1px solid #979797;
      border-radius: 3px;
      height: 560px;
      overflow-y: auto;
      margin-bottom: 70px;
    }

    .product-card {
      display: flex;
      align-items: center;

      padding: 0;
      background: #ffffff;
      border-radius: 3px;
      width: 100%;

      img {
        width: 80px;
        height: 66px;
        border-radius: 3px;
        margin-right: 17px;
      }

      p {
        font: italic bold 16px 'Times new roman';
        color: #545454;
        margin-bottom: 0;
      }

      span {
        text-transform: uppercase;
        font-size: 11px;
        color: #545454;
      }
    }
  }

  @media (min-width: 767px) {
    .content-modal {
      padding-bottom: 55px;
    }

    .modal-content.modal-out {
      max-width: 767px;
    }

    .ReactModal__Content {
      padding: 35px 44px 75px!important;
      left: 40px!important;
      right: 40px!important;
    }

    .dropzone {
      h3 {
        font: italic bold 22px 'Times new roman';
        margin-bottom: 30px;
        max-width: 100%;
      }

      p {
        bottom: 60px;
        font-size: 16px;
        max-width: 100%;
      }
    }

    .about-fields {
      padding-top: 70px;
      padding-bottom: 10px;

      display: flex;
      justify-content: space-between;

      .left-fields,
      .right-fields {
        width: 48%;
      }

      input,
      select {
        margin-bottom: 40px;
      }

      textarea {
        margin-top: 0;
        height: 397px;
      }
    }

    .button-submit {
      max-width: 380px;

      i {
        width: 83px;
      }
    }

    .used-products {
      margin-bottom: 85px;

      h3 {
        margin-bottom: 60px;
      }
    }

    .dd {
      flex-direction: row;

      &::after,
      &::before {
        left: calc(50% - 25px);
      }

      &::before {
        top: calc(50% - 40px);
        transform: rotate(0);
      }

      &::after {
        top: calc(50% + 20px);
        transform: rotate(180deg);
      }

      & > div {
        width: 45%!important;
        margin-bottom: 0px;
      }
    }
  }

  @media (min-width: 1200px) {
    .modal-content.modal-out {
      max-width: 1200px;
      padding: 50px 15px;
    }

    .ReactModal__Content {
      padding: 35px 100px 75px!important;
      max-width: 1200px;
      margin: 0 auto;
    }

    .about-fields {
      .left-fields,
      .right-fields {
        width: 49%;
      }
    }
  }
`;

const Options = styled.div`
  display: flex;
  align-content: space-between;
  margin-top: 25px;
  width: 100%;
  height: 113px;
  .holder {
    margin: 0 8px;
    width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    div {
      transition: all 0.3s;
      display: flex;
      width: 100%;
      z-index: 1;
    }
    .placeholder-thumbs {
      transition: all 0.3s;
      display: flex;
      width: 100%;
      position: absolute;
      left: 0;
      overflow: hidden;
      div {
        margin: 0 8px;
        border: 2px dashed #cecece;
        border-radius: 3px;
        height: 113px;
        min-width: calc(25% - 16px);

        @media (max-width: 1200px) {
          min-width: calc(33% - 16px);
        }
        @media (max-width: 767px) {
          min-width: calc(100% - 16px);
        }
      }
    }
    span {
      margin: 0 8px;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      overflow: hidden;
      height: 113px;
      min-width: calc(25% - 16px);
      border-radius: 3px;

      & > i.delete-file {
        width: 25px;
        height: 25px;
        background: #f3a94f;
        border-radius: 50%;
        position: absolute;
        right: 8px;
        bottom: 4px;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        font-style: normal;
      }

      @media (max-width: 1200px) {
        min-width: calc(33% - 16px);
      }
      @media (max-width: 767px) {
        min-width: calc(100% - 16px);
      }
      .overlay {
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: -8px;
        right: 0;
        bottom: 0;
        transition: all 0.3s;
        opacity: 0;
        min-width: 100%;
      }
      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }
  }
  button {
    min-width: 53px;
    background: #ebebeb;
    border-radius: 3px;
    border: 0;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 113px;
    @media (min-width: 1200px) {
      min-width: 83px;
    }
    &.right {
      background-image: url(/images/arrow-right-bold.png);
    }
    &.left {
      background-image: url(/images/arrow-left-bold.png);
    }
    &:hover {
      background-color: #e59134;
      &.right {
        background-image: url(/images/arrow-right.png);
        background-size: 22px 16px;
      }
      &.left {
        background-image: url(/images/arrow-right.png);
        background-size: 22px 16px;
        transform: rotate(180deg);
      }
    }
  }
`;

const ContainerColumns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #648540 url("/images/arrow-right.png") no-repeat center;

    top: calc(50% - 60px);
  }

  &::before {
    left: calc(50% - 60px);
    transform: rotate(90deg);
  }

  &::after {
    left: calc(50%);
    transform: rotate(-90deg);
  }

  & > div {
    width: 100% !important;
    border: 1px solid #979797;
    border-radius: 3px;
    height: 560px;
    overflow-y: auto;
    margin-bottom: 70px;
    background: #f4f4f4;
    padding: 20px 28px;
  }

  @media (min-width: 767px) {
    flex-direction: row;

    &::after,
    &::before {
      left: calc(50% - 25px);
    }

    &::before {
      top: calc(50% - 40px);
      transform: rotate(0);
    }

    &::after {
      top: calc(50% + 20px);
      transform: rotate(180deg);
    }

    & > div {
      width: 45% !important;
      margin-bottom: 0px;
    }
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;

  padding: 0;
  background: #ffffff;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 10px;

  img {
    width: 80px;
    height: 66px;
    border-radius: 3px;
    margin-right: 17px;
  }

  p {
    font: italic bold 16px "Times new roman";
    color: #545454;
    margin-bottom: 0;
  }

  span {
    text-transform: uppercase;
    font-size: 11px;
    color: #545454;
  }

  button {
    margin-left: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f3a94f;
    margin-right: 10px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 15px;
      height: auto;
      margin: 0;
    }
  }
`;

export default function Project({ pageContext }) {
  const results = useStaticQuery(graphql`
    {
      tipo_projeto: allPrismicTipoDeProjeto {
        edges {
          node {
            data {
              imagem {
                url
              }
              titulo {
                text
              }
            }
            uid
          }
        }
      }
      products: allPrismicProduto(
        sort: { fields: data___nome___text, order: ASC }
        filter: {
          data: { tipo_de_produto: { slug: { in: ["decorativos", "aris"] } } }
          lang: { eq: "pt-br" }
        }
      ) {
        edges {
          node {
            uid
            data {
              nome {
                text
              }
              imagem_principal {
                url
              }
              categoria_produto {
                uid
              }
              designs {
                design_produto {
                  uid
                }
              }
              tonalidades {
                tonalidade_produto {
                  uid
                }
              }
            }
          }
        }
      }
    }
  `);

  const getImages = (value) => {
    try {
      return JSON.parse(value);
    } catch (error) {
      return [];
    }
  };

  const { products, tipo_projeto } = results;

  const { getUser } = useContext(AuthContext);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [projectInfo, setProjectInfo] = useState({});

  const [pace, setPace] = useState(0);
  const [slide, setSlide] = useState(0);
  const [slideTotal, setSlideTotal] = useState(0);
  const [cidades, setCidades] = useState([]);
  const [productsSelected, setProductsSelected] = useState([]);

  const [files, setFiles] = useState(getImages(pageContext.gallery));
  const [images, setImages] = useState([]);

  const [searchResults, setSearchResults] = useState([]);

  const [moveResults, setMoveResults] = useState(
    getImages(pageContext.products)
  );

  async function handleSendForm(data) {
    const dataForm = {
      name: data.projectName,
      uf: data.projectState,
      city: data.projectCity,
      credit_maker: data.projectCreditMaker,
      link_maker: data.projectLinkMaker,
      credit_photography: data.projectCreditPhotography,
      link_photography: data.projectLinkPhotography,
      project_type: data.projectType,
      about: data.projectAbout,
      is_3d: data.is_3d,
      products: moveResults
        ? JSON.stringify(moveResults)
        : JSON.stringify(productsSelected),
      gallery: projectInfo.gallery
        ? projectInfo.gallery
        : JSON.stringify(images),
    };

    await api.post(
      `https://apimeuambiente.guararapes.com.br/project/${getUser.id}/update/${pageContext.id}/`,
      dataForm
    );

    alert(
      "Projeto editado com sucesso! As alterações podem demorar alguns minutos para serem atualizadas"
    );

    navigate("/meu-perfil");
  }

  const toggleEstado = (e) => {
    estados.map((item) => {
      if (item.sigla.toLowerCase() === e.target.value) setCidades(item.cidades);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      setFiles([
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            url: URL.createObjectURL(file),
          })
        ),
        ...files,
      ]);

      const data = new FormData();

      acceptedFiles.forEach((image, index) => {
        data.append(`file[${index}]`, image);
      });

      const response = await api.post(
        "https://apimeuambiente.guararapes.com.br/upload/",
        data
      );

      setImages(response.data.imgs);
    },
  });

  const resize = () => {
    if (document.body.clientWidth < 767) {
      setPace(100);
      setSlideTotal(files.length - 1);
    } else if (
      document.body.clientWidth > 768 &&
      document.body.clientWidth < 1200
    ) {
      setPace(33);
      setSlideTotal(files.length - 3);
    } else {
      setPace(25);
      setSlideTotal(files.length - 4);
    }
  };

  const thumbs = files.map((file, index) => (
    <span
      key={index}
      data-id={index}
      style={{ backgroundImage: `url(${file.url})` }}
    >
      <i className="delete-file" onClick={() => removeFile(index)}>
        x
      </i>
    </span>
  ));

  function removeFile(i) {
    files.splice(i, 1);
    setFiles([...files]);
  }

  useEffect(() => {
    async function loadInitialData() {
      const response = await api.get(
        `https://apimeuambiente.guararapes.com.br/post/${pageContext.id}`
      );
      setProjectInfo(response.data.project);
      setValue("projectState", response.data.project.uf);
      setValue("projectType", response.data.project.project_type);
      setSlideTotal(
        response.data.project.gallery !== null &&
          response.data.project.gallery !== ""
          ? JSON.parse(response.data.project.gallery).length
          : 0
      );
    }

    const toggleEstadoInitial = () => {
      estados.map((item) => {
        if (item.sigla.toLowerCase() === projectInfo.uf)
          setCidades(item.cidades);
      });
    };

    toggleEstadoInitial();

    files.forEach((file) => URL.revokeObjectURL(file.preview));
    loadInitialData();

    setSlideTotal(files.length);

    resize();

    window.addEventListener("resize", () => resize);

    return () => {
      window.removeEventListener("resize", () => resize);
    };
  }, [pageContext]);

  function slideLeft() {
    if (slide > 0) {
      setSlide(slide - 1);
    }
  }

  function slideRight() {
    if (slide < slideTotal) {
      setSlide(slide + 1);
    }
  }

  const handleEvent = (clickedTrack) => {
    let itemData = clickedTrack;
    if (typeof itemData.node != "undefined") {
      itemData = clickedTrack.node;
    }
    const uid = itemData.data.nome[0].text.toLowerCase().replace(" g", "-");
    const isInSearchResults = moveResults.some((result) => {
      let resultData = result;
      if (typeof resultData.node != "undefined") {
        resultData = result.node;
      }
      const item_uid = resultData.data.nome[0].text
        .toLowerCase()
        .replace(" g", "-");
      return item_uid === uid;
    });

    setMoveResults(
      !isInSearchResults
        ? [...moveResults, clickedTrack]
        : moveResults.filter((i) => {
            let iData = i;
            if (typeof iData.node != "undefined") {
              iData = i.node;
            }
            const item_uid = iData.data.nome[0].text
              .toLowerCase()
              .replace(" g", "-");
            return item_uid !== uid;
          })
    );
    setProductsSelected(
      !isInSearchResults
        ? [...moveResults, clickedTrack]
        : moveResults.filter((i) => {
            let iData = i;
            if (typeof iData.node != "undefined") {
              iData = i.node;
            }
            const item_uid = iData.data.nome[0].text
              .toLowerCase()
              .replace(" g", "-");
            return item_uid !== uid;
          })
    );
  };

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          {!modal && <Header />}
          <div className="modal-background">
            <form
              onSubmit={handleSubmit(handleSendForm)}
              className={!modal ? "modal-content modal-out" : "modal-content"}
            >
              <HeaderModal modal={modal} closeTo={closeTo} />

              <div className="select-pictures">
                <div>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <h3>Arraste suas fotos ou clique para selecionar.</h3>
                    <i></i>

                    <p>Envie arquivos .JPG e .PNG com até no máximo 1mb</p>
                  </div>
                  <aside className="list-preview">
                    <Options>
                      <button
                        type="button"
                        className="left"
                        onClick={slideLeft}
                      ></button>
                      <div className="holder">
                        <div className="placeholder-thumbs">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <div
                          style={{ transform: `translateX(-${slide * pace}%)` }}
                        >
                          {thumbs}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="right"
                        onClick={slideRight}
                      ></button>
                    </Options>
                  </aside>
                </div>
              </div>

              <div className="about-fields">
                <div className="left-fields">
                  <input
                    type="text"
                    placeholder="Nome do projeto *"
                    name="projectName"
                    className={
                      errors.projectName
                        ? "input-text input-error"
                        : "input-text"
                    }
                    ref={register({ required: true })}
                    defaultValue={projectInfo.name}
                  />

                  <fieldset className="half">
                    <select
                      onChange={toggleEstado}
                      name="projectState"
                      className={
                        errors.projectState
                          ? "input-select input-error"
                          : "input-select"
                      }
                      ref={register({ required: true })}
                    >
                      <option value="ac">Acre</option>
                      <option value="al">Alagoas</option>
                      <option value="am">Amazonas</option>
                      <option value="ap">Amapá</option>
                      <option value="ba">Bahia</option>
                      <option value="ce">Ceará</option>
                      <option value="df">Distrito Federal</option>
                      <option value="es">Espírito Santo</option>
                      <option value="go">Goiás</option>
                      <option value="ma">Maranhão</option>
                      <option value="mt">Mato Grosso</option>
                      <option value="ms">Mato Grosso do Sul</option>
                      <option value="mg">Minas Gerais</option>
                      <option value="pa">Pará</option>
                      <option value="pb">Paraíba</option>
                      <option value="pr">Paraná</option>
                      <option value="pe">Pernambuco</option>
                      <option value="pi">Piauí</option>
                      <option value="rj">Rio de Janeiro</option>
                      <option value="rn">Rio Grande do Norte</option>
                      <option value="ro">Rondônia</option>
                      <option value="rs">Rio Grande do Sul</option>
                      <option value="rr">Roraima</option>
                      <option value="sc">Santa Catarina</option>
                      <option value="se">Sergipe</option>
                      <option value="sp">São Paulo</option>
                      <option value="to">Tocantins</option>
                    </select>
                    <select
                      name="projectCity"
                      className={
                        errors.projectCity
                          ? "input-select input-error"
                          : "input-select"
                      }
                      ref={register({ required: true })}
                      defaultValue={projectInfo.city}
                    >
                      <option value={projectInfo.city}>
                        {projectInfo.city}
                      </option>
                      {cidades &&
                        cidades.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </fieldset>

                  <select
                    name="projectType"
                    className={
                      errors.projectType
                        ? "input-select input-error"
                        : "input-select"
                    }
                    ref={register({ required: true })}
                    defaultValue={projectInfo.project_type}
                  >
                    {tipo_projeto.edges.map((item, index) => {
                      return (
                        <option value={item.node.data.titulo.text} key={index}>
                          {item.node.data.titulo.text}
                        </option>
                      );
                    })}
                  </select>

                  <input
                    type="text"
                    placeholder="Créditos para a marcenaria"
                    className="input-text"
                    name="projectCreditMaker"
                    ref={register}
                    defaultValue={projectInfo.credit_maker}
                  />

                  <input
                    type="text"
                    placeholder="Link marcenaria"
                    className="input-text"
                    name="projectLinkMaker"
                    ref={register}
                    defaultValue={projectInfo.link_maker}
                  />

                  <input
                    type="text"
                    placeholder="Créditos para o Fotógrafo"
                    className="input-text"
                    name="projectCreditPhotography"
                    ref={register}
                    defaultValue={projectInfo.credit_photography}
                  />

                  <input
                    type="text"
                    placeholder="Link Fotógrafo"
                    className="input-text"
                    name="projectLinkPhotography"
                    ref={register}
                    defaultValue={projectInfo.link_photography}
                  />

                  <select
                    name="is_3d"
                    defaultValue={projectInfo.is_3d}
                    className={
                      errors.is_3d ? "input-select input-error" : "input-select"
                    }
                    ref={register({ required: true })}
                  >
                    <option value="">Projeto com imagens em 3d *</option>
                    <option value="0" selected={projectInfo.is_3d === 0}>
                      Não
                    </option>
                    <option value="1" selected={projectInfo.is_3d === 1}>
                      Sim
                    </option>
                  </select>
                </div>
                <div className="right-fields">
                  <textarea
                    name="projectAbout"
                    placeholder="Sobre o Projeto *"
                    className={
                      errors.projectAbout
                        ? "input-textarea input-error-area"
                        : "input-textarea"
                    }
                    ref={register({ required: true })}
                    defaultValue={projectInfo.about}
                  ></textarea>
                </div>
              </div>

              {products.edges && (
                <div className="used-products">
                  <h3>Produtos utilizados:</h3>
                  <ContainerColumns>
                    <Results
                      searchResults={products.edges}
                      handleEvent={handleEvent}
                      title="Selecione produtos utilizados no projeto"
                      icon="/images/arrow-right.png"
                    />
                    <Results
                      searchResults={moveResults}
                      handleEvent={handleEvent}
                      title="Produtos adicionados ao projeto"
                      icon="/images/streamline-icon-close-140-x-140.png"
                    />
                  </ContainerColumns>
                </div>
              )}

              <div className="submit-form">
                <button type="submit" className="button-submit">
                  <span>Editar projeto</span>
                  <i></i>
                </button>
              </div>

              <GlobalStyle />
            </form>
          </div>

          {!modal && (
            <>
              <Newsletter />
              <Footer />
            </>
          )}
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
}

function Results({ title, handleEvent, searchResults, icon }) {
  return (
    <div>
      <h4>{title}</h4>
      <ul>
        {searchResults &&
          searchResults.map((result, idx) => (
            <ResultItem
              key={`${result.id}-${idx}`}
              trackData={result}
              track={result}
              handleClick={handleEvent}
              icon={icon}
            />
          ))}
      </ul>
    </div>
  );
}

function ResultItem({ handleClick, track, trackData, icon }) {
  let item = {};
  if (trackData.node) item = trackData.node;
  else if (trackData.data) item = trackData;
  return (
    <Item>
      <img src={item.data.imagem_principal.url} alt={item.data.nome[0].text} />
      <div>
        <p>{item.data.nome[0].text}</p>
        {item.data.categoria_produto.uid && (
          <span>
            Linha{" "}
            {item.data.categoria_produto.uid &&
              item.data.categoria_produto.uid.split("-").join(" ")}
          </span>
        )}
      </div>
      <button type="button" onClick={() => handleClick(item)}>
        <img src={icon} alt="Icon action" />
      </button>
    </Item>
  );
}
